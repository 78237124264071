/**
 * Created by penyaev on 23.03.16.
 */

define('app',[
    'backbone',
    'underscore',
    'router',
    'markup',
    'external',
    'api',
    'counters',
    'querystring',
    'shares',

    'config',

    'data/articles',

    'views/intro',
    'views/menu',
    'views/article',

    'text!templates/watches.html',
    'text!templates/shares.html',

    'TweenLite', 'TimelineLite', 'CSSPlugin', 'rbc-menu', 'ScrollDepth'
], function (Backbone, _, Router, Markup, External, Api, Counters, QueryString, Shares,
             Config,
             Articles,
             IntroView, MenuView, ArticleView,
             WatchesTemplate, SharesTemplate) {
    return _.extend({
        api: null,
        counters: null,
        userState: {},
        router: null,
        userAvatar: null,
        watchesTemplate: _.template(WatchesTemplate),
        sharesTemplate: _.template(SharesTemplate),
        views: {
            intro: null
        },
        config: null,
        queryString: QueryString,
        ready: function () {
            var self = this;
            Backbone.history.start({
                //pushState: true,
                root: self.config.root
            });

            if (self.config.enableMarkup) {
                Markup.init();
            }
            this.circleGroupAnimation.seek(0).resume();
        },
        createCircleGroup: function ($container, minOuterDiameter) {
            var self = this;
            var diameterStep = 35.5;
            var steps = Math.ceil(minOuterDiameter / diameterStep);

            this.circleGroupAnimation = new TimelineLite({
                paused: true
            });

            var $nextContainer = $container;
            for (var step = steps; step > 0; --step) {
                var diameter = diameterStep * step;
                var $circle = $('<div>').addClass('b-circle').css({
                    width: diameter + 'rem',
                    height: diameter + 'rem',
                    opacity: 0
                });
                $circle
                    .addClass('b-circle_inner')
                    .addClass('b-circle_level-' + step)
                ;
                if (step == 1) {
                    $circle
                        .addClass('b-circle_center')
                        .html(self.watchesTemplate({index: 1}))
                    ;
                }
                if (step > 2) {
                    var $planet = $('<div>').addClass('b-circle__planet').addClass('b-circle__planet_' + step);
                    $circle.append($planet);
                }
                $nextContainer.append($circle);
                $nextContainer = $circle;

                TweenLite.set($circle, {scale: 1.1});
                self.circleGroupAnimation.add(TweenLite.to($circle, 0.2, {
                    opacity: 1,
                    scale: 1,
                    ease: Power1.easeOut,
                    force3D: false
                }));
            }

            var positionLeft = 44.7;
            var positionTop = 22.9 + 3.7; // 3.7 = header height

            var offsetLeft = -((diameterStep + 0.2) * (steps + 1)) / 2 + positionLeft;
            var offsetTop = -((diameterStep + 0.2) * (steps + 1)) / 2 + positionTop;

            $container.css({
                left: offsetLeft + 'rem',
                top: offsetTop + 'rem'
            });
        },
        start: function () {
            jQuery.scrollDepth({
                userTiming: false
            });

            $('body')
                .on('click', '.js-logo', function() { ga("send", "event", "brand button", "clicked header", location.href) })
                .on('click', '.js-readmore-btn', function() { ga("send", "event", "brand button", "clicked learn more", location.href) });


            var self = this;

            self.config = Config;

            var mob = Markup.checkMobile();
            if (mob) {
                $('html').addClass('mob');
                self.config.mob = true;
                self.config.enableMarkup = false;
            }

            this.listenTo(this, 'ready', this.ready);

            self.counters = Counters;
            self.api = Api;
            Api.init(self.config.apiUrl);

            var viewOptions = {
                app: this
            };

            this.views.intro = new IntroView(_.extend({
                // additional options to pass to view
            }, viewOptions));

            this.views.article = new ArticleView(_.extend({
                // additional options to pass to view
            }, viewOptions));

            this.menu = new MenuView(_.extend({}, viewOptions));
            this.menu.render();

            this.router = new Router();
            this.listenTo(this.router, 'route', this.route);

            if (!mob) {
                $('.b-wrapper__inner').append($(self.sharesTemplate()));
                Shares.init({}, $('.b-share'));
            }

            if (!self.config.noApi) {
                Api.apiCall('user.login', this.queryString, {
                    method: 'post',
                    onSuccess: function (response) {
                        Api.setApiPersistentData('logged_user_id', response.data.userId);
                        Api.setApiPersistentData('api_key', response.data.apiKey);
                        self.userState = $.parseJSON(response.data.state);

                        Api.apiCall('network.avatar', {}, {
                            method: 'get',
                            onSuccess: function (response) {
                                if ('avatar' in response.data) {
                                    self.userAvatar = response.data['avatar'];
                                }
                            }
                        });

                        self.trigger('ready');
                    }
                });
            } else {
                this.createCircleGroup($('#circle-container'), 280);
                self.trigger('ready');
            }
        },

        _findArticle: function (slug) {
            var article = _.find(Articles.left, function (article) {
                return article.slug === slug;
            });
            if (!article) {
                article = _.find(Articles.right, function (article) {
                    return article.slug === slug;
                });
            }
            if (!article) {
                article = _.find(Articles.extra, function (article) {
                    return article.slug === slug;
                });
            }
            return article;
        },

        route: function (routeName, args) {
            var self = this;
            var view = null;
            var options = {};

            jQuery.scrollDepth.reset();

            this.views.intro.clearTimeout();
            this.views.article.clearTimeout();

            switch (routeName) {
                case "intro":
                    view = this.views.intro;
                    view.init();
                    break;
                case "article":
                    view = this.views.article;
                    var slug = args[0];
                    var article = this._findArticle(slug);
                    if (!article) { // article not found, redirect to home
                        self.go('/');
                        return;
                    }

                    var footers = [];
                    _.each(article.footers, function (item) {
                        var footerArticle = self._findArticle(item);
                        footers.push(footerArticle);
                    });

                    options.article = Object.assign({}, article, {footers: footers});
                    break;
                default:
                case "default":
                    self.go('/');
                    break;
            }
            if (view) {
                view.render(options);
                view.setTimeout();

                ga("set", "page", location.pathname + location.hash);
                ga("send", "pageview", location.pathname + location.hash);
            }
        },
        okUpdateWindowSize: function () {
            if (this.okApi) {
                var $body = $('.b-wrapper');
                var newWidth = $body.width();
                var newHeight = Math.min($body.height(), screen.height * 0.6);
                if (newWidth != this.lastWidth || newHeight != this.lastHeight) {
                    this.okApi.UI.setWindowSize(newWidth, newHeight);
                    this.lastWidth = $body.width();
                    this.lastHeight = $body.height();
                }
            }
        },
        saveState: function () {
            var self = this;
            self.api.apiCall('user.savestate', {
                "state": JSON.stringify(self.userState)
            }, {
                method: 'post'
            });
        },
        go: function (path) {
            Backbone.history.navigate(path, {
                trigger: true
            });
        }
    }, Backbone.Events);
});

