/**
 * Created by penyaev on 06.04.16.
 */

define('locker',[],function () {
    window.__locks = {};

    var _public = {
        lock: function (key) {
            if (window.__locks[key]) {
                return false;
            }
            window.__locks[key] = true;
            return true;
        },
        unlock: function (key) {
            if (window.__locks[key]) {
                delete window.__locks[key];
            }
        }
    };

    return _public;
});

