'use strict';

define('rbc-menu',['jquery'], function ($) {
  var $burger = $('.b-burger');
  var $menu = $('.b-header__menu');
  var menuShiftedClass = 'b-header__menu_shifted';
  var autoCloseTimeout = 2000;
  var autoCloseTimeoutId = null;

  $burger.click(function(){
    $menu.addClass(menuShiftedClass);
  });

  $menu.bind('mouseleave', function(){
    autoCloseTimeoutId = setTimeout(function(){
      $menu.removeClass(menuShiftedClass);
    }, autoCloseTimeout);
  });

  $menu.bind('mouseenter', function(){
    clearTimeout(autoCloseTimeoutId);
  })
});


