/*global require*/
'use strict';

require.config({
    shim: {
        'slideme': ['jquery'],
        'slides': ['jquery']
    },
    paths: {
        templates: './templates',
        jquery: '../../bower_components/jquery/dist/jquery',
        backbone: '../../bower_components/backbone/backbone',
        underscore: '../../bower_components/lodash/dist/lodash',
        text: '../../bower_components/requirejs-text/text',
        TweenLite: '../../bower_components/gsap/src/uncompressed/TweenLite',
        TimelineLite: '../../bower_components/gsap/src/uncompressed/TimelineLite',
        CSSPlugin: '../../bower_components/gsap/src/uncompressed/plugins/CSSPlugin',
        ScrollDepth: '../../bower_components/scroll-depth/jquery.scrolldepth.min',
        slides: './lib/jquery.slides.min',
        slideme: './lib/jquery.slideme-1.21.71'
    }
});

require(['app'], function (App) {
    App.start();
});

define("main", function(){});

