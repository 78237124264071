/**
 * Created by penyaev on 08.08.16.
 */


define('config',[],function () {
    return {
        noApi: true,
        enableMarkup: true,

        apiUrl: 'http://localhost:8080/',
        root: "/pwptp/app/",

        shareBase: 'http://omegario2016.sport.rbc.ru/',
        staticRoot: 'http://omega.dev.ktsstudio.ru/static/'
    };
});

