/**
 * Created by penyaev on 01.12.15.
 */
define('counters',[
  'data/counters'
], function (Counters) {
  return {
    getCounterUrl: function (counter_id, isMobile) {
      var version = isMobile ? 'mobile' : 'web';
      var counter = Counters[counter_id];
      if (!counter) {
        return '';
      }
      return counter[version];
    },
    getCounterImg: function (counter) {
      var url = this.getCounterUrl(counter);
      var img = '';
      if (url) {
        img = '<img src="' + url + '" style="width:0;height:0;position:absolute;visibility:hidden;" alt="" />';
      }
      return img;
    },
    hit: function (counter) {
      (new Image()).src = this.getCounterUrl(counter);
    }
  };
});

