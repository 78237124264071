'use strict';

define('views/menu',[
    'jquery',
    'backbone',
    'text!templates/menu.html'
], function ($, Backbone, MenuTemplate) {
    return Backbone.View.extend({
        el: '.b-wrapper__inner',
        events: {
            'click .b-menu__burger': 'showMenu',
            'click .b-menu__close': 'hideMenu',
            'click .js-menu__close': 'hideMenu',
            'click .b-menu__item': 'hideMenu',
            'click .b-menu__back': 'hideMenu',
            'keydown': 'handleKeydown'
        },
        template: _.template(MenuTemplate),
        initialize: function (options) {
            this.app = options.app;
            this.closeId = null;
            this.node = $(this.template());
            this.$wrapper = this.node.find('.b-menu__wrapper');
            this.$burger = this.node.find('.b-menu__burger');
        },
        showMenu: function() {
            clearTimeout(this.closeId);
            this.$wrapper.addClass('b-menu__wrapper_visible');
            this.$wrapper[0].offsetWidth; // force redraw
            this.$wrapper.addClass('b-menu__wrapper_opened');
            var className = this.app.config.mob ? 'is-active js-menu__close' : 'b-menu__burger_hidden';
            this.$burger.addClass(className);
            $('.b-menu__scroll').scrollTop(0);
        },
        hideMenu: function() {
            var self = this;
            self.$burger.removeClass('b-menu__burger_hidden').removeClass('is-active').removeClass('js-menu__close');
            this.$wrapper.removeClass('b-menu__wrapper_opened');
            this.closeId = setTimeout(function() {
                self.$wrapper.removeClass('b-menu__wrapper_visible');
            }, 300);
        },
        handleKeydown: function(event){
            var ESC_KEYCODE = 27;
            var code = event.keyCode || event.which;

            if (code === ESC_KEYCODE) {
                this.hideMenu();
            }
        },
        render: function () {
            var self = this;

            var menuInEl = this.$el.has(this.node);

            // Меню аппендится в основной контейнер
            if (menuInEl.length === 0) {
                this.$el.append(this.node);
            }
        }
    });
});

