define('templates/articles_mob/articles', [
    'text!templates/articles_mob/1.html',
    'text!templates/articles_mob/2.html',
    'text!templates/articles_mob/3.html',
    'text!templates/articles_mob/4.html',
    'text!templates/articles_mob/5.html',
    'text!templates/articles_mob/6.html',
    'text!templates/articles_mob/7.html',
    'text!templates/articles_mob/8.html',
    'text!templates/articles_mob/9.html',
    'text!templates/articles_mob/10.html'
], function () {
    var result = {};
    for (var i in arguments) {
        result[parseInt(i) + 1 + '.html'] = arguments[i]
    }
    return result;
});

