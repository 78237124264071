/**
 * Created by penyaev on 06.08.16.
 */


define('views/article',[
    'backbone',
    'templates/articles/articles',
    'templates/articles_mob/articles',
    'text!templates/article.html',
    'text!templates/footer_readmore.html',
    'shares',
    'text!templates/shares.html',
    'jquery',
    'slideme',
    'slides'
], function (
    Backbone,
    ArticlesTemplates,
    ArticlesMobTemplates,
    ArticleTemplate,
    FooterTemplate,
    Shares,
    SharesTemplate
) {
    return Backbone.View.extend({
        el: '#js-container',
        app: null,
        events: {
            'click .js-video-4': 'video4',
            'click .js-video-5': 'video5'
        },
        template: _.template(ArticleTemplate),

        video4: function(el) {
            var name = "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ ЗАБЕГА НА 100 МЕТРОВ";
            ga("send", "event", "video", "start", name);
            this.player4 = new YT.Player('js-video-4-player', {
                height: '100%',
                width: '100%',
                videoId: '2KHbhWigxB8',
                playerVars: { 'autoplay': 1, 'rel': 0, 'modestbranding': 1 },
                events: {
                    'onStateChange': function (event) {
                        if (event.data == YT.PlayerState.ENDED) {
                            ga("send", "event", "video", "finish", name);
                        }
                    }
                }
            });
        },

        video5: function(el) {
            var name = "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ ЗАПЛЫВА НА 100 МЕТРОВ ВОЛЬНЫМ СТИЛЕМ";
            ga("send", "event", "video", "start", name);
            this.player5 = new YT.Player('js-video-5-player', {
                height: '40.6rem',
                width: '72rem',
                videoId: 'Ep-fDLp-ZrQ',
                playerVars: { 'autoplay': 1, 'rel': 0, 'modestbranding': 1 },
                events: {
                    'onStateChange': function(event) {
                        if (event.data == YT.PlayerState.ENDED) {
                            ga("send", "event", "video", "finish", name);
                        }
                    }
                }
            });
        },

        initialize: function (options) {
            var self = this;
            this.app = options.app;

            $('body').on('click', '.js-article-close', function () {
                TweenLite.to(self.$el, 0.8, {
                    opacity: 0,
                    ease: Power2.easeInOut,
                    onComplete: function () {
                        $('body,.b-wrapper2').scrollTop(0);
                        self.app.go('/');
                    }
                });
            })
                .on('click', '.js-to-top', function() {
                    $('body,.b-wrapper2').stop().animate({ scrollTop: 0 }, 500);
                });
        },

        setTimeout: function () {
            this.timeout = setTimeout(function() {
                ga("send", "event", "page", "spent_on_page_15_sec");
            }, 15000);
        },

        clearTimeout: function() {
            clearTimeout(this.timeout);
        },

        initSliders: function() {
            var sliderOpts = {
                css3 : true,
                transition : 'page',
                touch : true,
                nativeTouchScroll : true,
                swipe : true,
                arrows: true,
                loop: true,
                labels : {
                    next: '',
                    prev: ''
                }
            };

            var slider = {
                navigation: {
                    active: false
                },
                pagination: {
                    active: false
                }
            };

            var sliderM = {
                width: 290,
                height: 202
            };

            var sliderL = {
                width: 290,
                height: 234
            };

            var sliderXL = {
                width: 290,
                height: 274
            };

            var sliderXXL = {
                width: 290,
                height: 284
            };

            $('.slider_m').slidesjs(Object.assign({},
                slider,
                sliderM
            ));

            $('.slider_l').slidesjs(Object.assign({},
                slider,
                sliderL
            ));

            $('.slider_xl').slidesjs(Object.assign({},
                slider,
                sliderXL
            ));

            $('.slider_xxl').slidesjs(Object.assign({},
                slider,
                sliderXXL
            ));

            //$('.slider_m').slideme(Object.assign({},
            //    {
            //        resizable: {
            //            width: 290,
            //            height: 202
            //        }
            //    },
            //    sliderOpts
            //));
            //
            //$('.slider_l').slideme(Object.assign({},
            //    {
            //        resizable: {
            //            width: 290,
            //            height: 244
            //        }
            //    },
            //    sliderOpts
            //));
        },

        render: function (options) {
            var self = this;

            if (self.$el.is(':visible') && !options.disableFadeOutAnimation) {
                TweenLite.to(self.$el, 0.8, {
                    opacity: 0,
                    ease: Power2.easeInOut,
                    onComplete: self.render.bind(self, _.extend(options, {
                        disableFadeOutAnimation: true
                    }))
                });
                return;
            }

            self.$el.css({opacity: 0}).show();
            $(document).off('mousewheel DOMMouseScroll touchmove keydown');
            $('body').off('keydown');
            $('.b-wrapper2').removeClass('overflow_hidden');
            this.$el.removeClass('b-container_intro');

            var templates = self.app.config.mob ? ArticlesMobTemplates : ArticlesTemplates;
            var articleHtml = templates[options.article.html];
            self.$el.html(self.template({
                mobile: self.app.config.mob,
                shares: _.template(SharesTemplate)(),
                article: options.article,
                articleHtml: articleHtml,
                footerHtml: _.template(FooterTemplate)({article: options.article, mobile: self.app.config.mob})
            }));

            Shares.init({}, $('.b-share'));

            self.initSliders();

            $('link[rel=image_src]').attr('href', self.app.config.shareBase + options.article.image);
            $('title').html(options.article.title.replace(/<br>/g, " "));
            if (options.article.description) {
                $('meta[name=description]').attr('content', options.article.description.replace(/<br>/g, " "));
            }

            TweenLite.to(self.$el, 0.8, {
                opacity: 1,
                ease: Power2.easeInOut
            });
            $('body,.b-wrapper2').scrollTop(0);
            $('.b-buttons').hide();

            return this;
        }
    });
});

