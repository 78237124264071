define('shares',['jquery', 'config'], function($, config) {
  'use strict';

  var module = {
    params: {
      mm: '.b-headline__button_counter-mm',
      ok: '.b-headline__button_counter-ok',
      fb: '.b-headline__button_counter-fb',
      vk: '.b-headline__button_counter-vk',
      tw: '.b-headline__button_counter-tw'
    },
    isInit: false,
    shareUrl: {
      mm: 'http://connect.mail.ru/share?description={d}&title={t}&image_url={i}&url=',
      ok: 'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=',
      // prod 1056206827789230
      // test 1056210297788883
      fb: 'https://www.facebook.com/dialog/feed?app_id=1056206827789230&display=popup&name={t}&description={d}&picture={i}&redirect_uri={u}&link=',
      vk: 'http://vk.com/share.php?description={d}&title={t}&image={i}&url=',
      tw: 'https://twitter.com/intent/tweet?text={t}&url=',
      gp: 'https://plus.google.com/share?hl=ru&url='
    },
    init: function(params, scope) {
      var self = this;

      //if (!self.isInit) {

      if (typeof(params) === 'object') {
        $.extend(self.params, params);

        if (!params.noCounters) {
          self.registerGlobals();
          self.updateCouters();
        }
      } else if (typeof(params) === 'string') {
        for (var service in self.params) {
          self.params[service] = params + service;
        }
      }

      if (!scope) {
        scope = document;
      }

      var clickHandler = function(event) {
        event.preventDefault();

        self.share(this);
      };

      $(scope).find('[data-share]').unbind('click', clickHandler);
      $(scope).find('[data-share]').bind('click', clickHandler);

      self.isInit = true;
      //}

      return this;
    },
    registerGlobals: function() {
      var self = this;

      window.updateMailCount = function(data) {
        if (Object.keys(data).length !== 0) {
          for (var url in data) {
            if (parseInt(data[url].shares) === 0) {
              $(self.params.mm).hide();
            } else {
              $(self.params.mm).text(data[url].shares);
              $(self.params.mm).css('display', 'inline-block');
            }
          }
        } else {
          $(self.params.mm).hide();
        }
      };

      window.ODKL = {};
      window.ODKL.updateCount = function(fish1, count) {
        if (parseInt(count) === 0) {
          $(self.params.ok).hide();
        } else {
          $(self.params.ok).text(count);
          $(self.params.ok).css('display', 'inline-block');
        }
      };

      window.VK = {};
      window.VK.Share = {};
      window.VK.Share.count = function(index, count) {
        if (parseInt(count) === 0) {
          $(self.params.vk).hide();
        } else {
          $(self.params.vk).text(count);
          $(self.params.vk).css('display', 'inline-block');
        }

      };

      window.updateFBCounter = function(data) {
        /* jshint camelcase: false */
        if (data && data.length !== 0) {
          if (parseInt(data[0].share_count) === 0) {
            $(self.params.fb).hide();
          } else {
            $(self.params.fb).text(data[0].share_count);
            $(self.params.fb).css('display', 'inline-block');
          }
        }
      };

      // window.updateTWCounter = function(data){
      //     if (!data.hasOwnProperty('count') || data.count === 0) {
      //         $(self.params.tw).hide();
      //     } else {
      //         $(self.params.tw).text(data.count);
      //         $(self.params.tw).css('display', 'inline-block');
      //     }
      // };
    },
    updateCouters: function() {
      var pageuri = encodeURIComponent(config.shareBase + location.pathname);
      $.ajax({ dataType: 'jsonp', url: '//connect.mail.ru/share_count?callback=1&func=updateMailCount&url_list=' + pageuri });
      $.ajax({ dataType: 'jsonp', url: '//www.odnoklassniki.ru/dk?st.cmd=extLike&uid=odklocs0&ref=' + pageuri });
      $.get('//api.facebook.com/restserver.php?method=links.getStats&format=json&urls=' + pageuri).success(window.updateFBCounter);
      $.ajax({ dataType: 'jsonp', url: '//vk.com/share.php?act=count&index=1&format=json&url=' + pageuri });
      // $.ajax({dataType: 'jsonp', url: '://cdn.api.twitter.com/1/urls/count.json?callback=updateTWCounter&url=' + pageuri});
    },
    getServiceLink: function(service, title, description, image, link) {
      if (!title) { title = $('title').text().replace(/^\s*(.+\S+)\s*$/, '$1'); }
      if (!description) { description = $('meta[name=description]').attr('content'); }
      if (!image) { image = $('link[rel=image_src]').attr('href'); }
      if (!link || link === '#') { link = location.hash; }

      var host = config.shareBase,
        out = this.shareUrl[service].replace(/{t}/, encodeURIComponent(title));
      //var out = this.shareUrl[service].replace(/{t}/, encodeURIComponent(title));
      out = out.replace(/{d}/, encodeURIComponent(description));
      //out = out.replace(/{d}/, encodeURIComponent(description));
      if (/^http/.test(image)) {
        out = out.replace(/{i}/, encodeURIComponent(image));
      } else {
        out = out.replace(/{i}/, encodeURIComponent(config.staticRoot + image));
      }

      out = out.replace(/{u}/, encodeURIComponent(host + '/fb-share-end.html'));

      return out + encodeURIComponent(host + link);
    },
    share: function(elem) {
      var $elem = $(elem);
      var left = (screen.width / 2) - (580 / 2);
      var top = (screen.height / 2) - (420 / 2);

      var service = $elem.attr('data-share');
      var social = $elem.attr('data-social');
      var title = $elem.attr('data-title');
      // var desc = $($elem.attr('data-desc')).text(); //strip html
      var desc = $elem.attr('data-desc');
      var image = $elem.attr('data-image');
      var url = $elem.attr('href');

      ga("send", "event", "share", "clicked share button", social);

      var shareUrl = this.getServiceLink(service, title, desc, image, url);

      window.open(shareUrl, '_blank', 'width=580,height=420,top=' + top + ',left=' + left);
    }
  };

  return module;
});

