/**
 * Created by penyaev on 24.11.15.
 */


define('router',[
  'backbone'
], function (Backbone) {
  return Backbone.Router.extend({
    routes: {
      "": "intro",
      "articles/:slug": "article",
      "*default": "default"
    },
    initialize: function () {
      $(document).on("click", "a:not(.pass)", function (evt) {
        evt.preventDefault();
        Backbone.history.navigate($(this).attr('href'), {
          trigger: true
        });
      });
    }
  });
});

