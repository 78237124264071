/**
 * Created by penyaev on 24.11.15.
 */


define('views/intro',[
    'jquery',
    'backbone',
    'locker',
    'data/articles',
    'text!templates/intro.html',
    'text!templates/intro_mob.html',
    'text!templates/main-photo-text.html',
    'text!templates/watches.html',

    'TweenLite', 'TimelineLite', 'CSSPlugin'
], function ($, Backbone, Locker, Articles, IntroTemplate, IntroMobTemplate, MainPhotoTextTemplate, WatchesTemplate) {
    return Backbone.View.extend({
        el: '#js-container',
        app: null,
        currentArticleLeft: -1,
        currentArticleRight: -1,
        events: {},
        scrollEvents: [],
        template: _.template(IntroTemplate),
        templateMob: _.template(IntroMobTemplate),
        watchesTemplate: _.template(WatchesTemplate),
        mainPhotoTextTemplate: _.template(MainPhotoTextTemplate),
        initialize: function (options) {
            var self = this;
            this.app = options.app;

            $('body')
                .on('click', '.b-intro-arrow', self.movePhotoScene.bind(self, true, false, false, null))
                .on('keydown', self.onKeyDown.bind(self))
                .on('click', '.b-button_up', self.movePhotoScene.bind(self, false, false, false, null))
                .on('click', '.b-button_down', self.movePhotoScene.bind(self, true, false, false, null))
                .on('click', '.js-watches-arrow', function (e) {
                    var $currentTarget = $(e.currentTarget);
                    var $container = $currentTarget.parents('.b-intro__watches');
                    var directionLeft = ($currentTarget.attr('data-direction') === 'left');
                    self.moveWatches.call(self, $container, !directionLeft, false, true);
                    return false;
                })
                .on('click', '.js-article-link', function (e) {
                    if ($('#js-container').is(':visible')) {
                        return true;
                    }
                    var $currentTarget = $(e.currentTarget);
                    self.movePhotoScene(true, false, true, function () {
                        self.app.go($currentTarget.attr('href'));
                    });
                    return false;
                });
            if (!this.app.config.mob) {
                self.mouseWheelOn($(document), true, function (dir) {
                    self.movePhotoScene(dir);
                });
            }
        },

        onKeyDown: function(e) {
            var self = this;
            if (e.which === 32 || e.which === 40 || e.which === 34) {
                self.movePhotoScene(true, false, false, null);
            } else if (e.which === 38 || e.which === 33) {
                self.movePhotoScene(false, false, false, null);
            }
        },

        mouseWheelOn: function ($container, vertical, callback, defaultReturn) {
            var self = this;
            var lastTouchCoord = 0;
            $container.on('mousewheel DOMMouseScroll touchmove', function (e) {
                var delta;
                if (e.originalEvent.type === 'touchmove') {
                    var position = (vertical ? e.originalEvent.changedTouches[0].clientY : e.originalEvent.changedTouches[0].clientX);
                    delta = position - lastTouchCoord;
                    lastTouchCoord = position;
                } else {
                    delta = e.originalEvent.detail ? e.originalEvent.detail * (-120) : (vertical ? e.originalEvent.wheelDeltaY : e.originalEvent.wheelDeltaX);
                }
                self.scrollEvents.push(delta);
                if (self.scrollEvents.length < 4) {
                    return !!defaultReturn;
                }
                self.scrollEvents = self.scrollEvents.slice(-4);
                var prevY = self.scrollEvents[0];
                var prevDir = null, currentDir, delta, checkOk = true;
                for (var i = 0; i < self.scrollEvents.length; ++i) {
                    if (self.scrollEvents[i] == 0) {
                        return !!defaultReturn;
                    }
                    currentDir = (self.scrollEvents[i] > 0) ? 1 : -1;
                    if (prevDir == null) {
                        prevDir = currentDir;
                    }
                    if (prevDir != currentDir) {
                        return !!defaultReturn;
                    }
                }

                if (!Locker.lock('mousewheel')) {
                    return !!defaultReturn;
                }
                setTimeout(function () {
                    Locker.unlock('mousewheel');
                }, 1300);
                return callback(prevDir < 0, e);
            });
        },

        hideIntroSlide: function (reverse, callback) {
            if (reverse) {
                $('#js-container').show();
            }
            TweenLite.to($('.b-intro-content,.b-intro-title'), 0.8, {
                opacity: reverse ? 1 : 0,
                top: reverse ? '+=50rem' : '-=50rem',
                ease: Power2.easeInOut,
                force3D: false
            });
            TweenLite.to($('.b-intro-arrow'), 0.8, {
                opacity: reverse ? 1 : 0,
                top: reverse ? '-=50rem' : '+=50rem',
                ease: Power2.easeInOut,
                force3D: false,
                onComplete: function () {
                    if (!reverse) {
                        $('#js-container').hide();
                    }
                    if (callback) {
                        setTimeout(callback, 0);
                    }
                }
            });
            if (!reverse) {
                $('.b-buttons').css({
                    display: 'block'
                });
            }
            TweenLite.to($('.b-buttons'), 0.8, {
                bottom: reverse ? '-7rem' : 0,
                opacity: reverse ? 0 : 1,
                onComplete: function () {
                    if (reverse) {
                        $('.b-buttons').css({
                            display: 'none'
                        });
                    }
                }
            });

            return false;
        },
        moveWatches: function ($container, right, cleanUp, forceMovement) {
            var $currentWatchesImage = $container.find('.b-intro__watches-image_active');
            var $nextWatchesImage = right ? $currentWatchesImage.next() : $currentWatchesImage.prev();
            if (!$nextWatchesImage.length) {
                if (right) {
                    $nextWatchesImage = $container.find('.b-intro__watches-image').first();
                } else {
                    $nextWatchesImage = $container.find('.b-intro__watches-image').last();
                }
            }
            var duration = 0.7;
            if (this.currentArticleLeft == 0 && right && !cleanUp && !forceMovement) {
                TweenLite.to($('.b-intro__watches'), duration, {
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false
                });
            } else if (((this.currentArticleLeft == -1 && !right) || cleanUp) && !forceMovement) {
                TweenLite.to($('.b-intro__watches'), duration, {
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false
                });
            } else if ($nextWatchesImage.length) {
                TweenLite.to($currentWatchesImage, duration, {
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $currentWatchesImage.removeClass('b-intro__watches-image_active');
                    }
                });
                TweenLite.to($nextWatchesImage, duration, {
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $nextWatchesImage.addClass('b-intro__watches-image_active');
                    }
                });
                var newLeft = - (parseInt($nextWatchesImage.attr('data-index'))-1) * 16.2 - 8.1;
                TweenLite.to($container.find('.b-intro__watches-images'), duration, {
                    marginLeft: newLeft + 'rem',
                    ease: Power2.easeInOut,
                    force3D: false
                });

                var $currentWatchesLink = $container.find('.b-intro__watches-link_active');
                var $nextWatchesLink = right ? $currentWatchesLink.next() : $currentWatchesLink.prev();
                if (!$nextWatchesLink.length) {
                    if (right) {
                        $nextWatchesLink = $container.find('.b-intro__watches-link').first();
                    } else {
                        $nextWatchesLink = $container.find('.b-intro__watches-link').last();
                    }
                }

                var linkDuration = duration - 0.3;

                TweenLite.to($currentWatchesLink, linkDuration, {
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $currentWatchesLink.removeClass('b-intro__watches-link_active');
                    }
                });
                $nextWatchesLink.css({
                    opacity: 0,
                    visibility: 'visible'
                });
                TweenLite.to($nextWatchesLink, linkDuration, {
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $nextWatchesLink
                            .css({
                                visibility: ''
                            })
                            .addClass('b-intro__watches-link_active');
                    }
                });
                var newTop = -(parseInt($nextWatchesLink.attr('data-index')) - 1) * 5;
                TweenLite.to($container.find('.b-intro__watches-links'), duration, {
                    marginTop: newTop+'rem',
                    ease: Power2.easeInOut
                });
            }
        },
        movePhotoScene: function (down, ignoreIntroSlide, cleanUp, callback) {
            if (!ignoreIntroSlide && !Locker.lock('movePhotoScene')) {
                return;
            }
            var self = this;
            var sideOffset = '6rem';
            var topOffset = '42rem';

            if (this.currentArticleLeft == -1 && down && !ignoreIntroSlide) {
                setTimeout(function () {
                    this.hideIntroSlide(false, this.movePhotoScene.bind(this, down, true, false));
                }.bind(this), 0);

                return;
            }
            // disable cycling
            if (this.currentArticleLeft == -1 && !down) {
                // this.currentArticleLeft = this.currentArticleRight = Math.max(Articles.left.length, Articles.left.length);
                // setTimeout(function () {
                //     this.hideIntroSlide(false, this.movePhotoScene.bind(this, false, true, false));
                // }.bind(this), 0);
                Locker.unlock('movePhotoScene');
                return;
            }

            var nextArticleLeft = null, nextArticleRight = null;
            var dummy = false;
            if (down == -2) {
                dummy = true;
            }

            if (!cleanUp && !dummy) {
                var newArticleLeft = this.currentArticleLeft + (down ? +1 : -1);
                var newArticleRight = this.currentArticleRight + (down ? +1 : -1);

                if (newArticleLeft >= 0 && newArticleLeft < Articles.left.length) {
                    nextArticleLeft = Articles.left[newArticleLeft];
                }
                if (newArticleRight >= 0 && newArticleRight < Articles.right.length) {
                    nextArticleRight = Articles.right[newArticleRight];
                }

                // disable cycling
                if (!nextArticleLeft && !nextArticleRight && down) {
                    // this.currentArticleLeft = this.currentArticleRight = 0;
                    Locker.unlock('movePhotoScene');
                    // self.movePhotoScene(false, false, false, callback);
                    return;
                }

                this.currentArticleLeft = newArticleLeft;
                this.currentArticleRight = newArticleRight;
            }

            var $currentPhotoLeft = $('.b-photo-main_current.b-photo-main_left');
            var $currentPhotoTextLeft = $('.b-photo-text_current.b-photo-text_left');
            var $currentPhotoRight = $('.b-photo-main_current.b-photo-main_right');
            var $currentPhotoTextRight = $('.b-photo-text_current.b-photo-text_right');

            if ($currentPhotoLeft.length && !dummy) {
                TweenLite.to($currentPhotoLeft, 1.0, {
                    marginTop: down ? '-' + topOffset : topOffset,
                    marginLeft: sideOffset,
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $currentPhotoLeft.remove();

                        if (self.currentArticleLeft < 0 || self.currentArticleRight < 0) {
                            setTimeout(self.hideIntroSlide.bind(this, true, function () {
                                Locker.unlock('movePhotoScene');
                                if (callback) {
                                    callback();
                                }
                            }), 0);
                        } else {
                            Locker.unlock('movePhotoScene');
                            if (callback) {
                                callback();
                            }
                        }
                    }
                });
            }
            if ($currentPhotoTextLeft.length && !dummy) {
                TweenLite.to($currentPhotoTextLeft, 1.0, {
                    top: down ? '-=' + topOffset : '+=' + topOffset,
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $currentPhotoTextLeft.remove();
                    }
                });
            }

            if ($currentPhotoRight.length && !dummy) {
                TweenLite.to($currentPhotoRight, 1.0, {
                    marginTop: down ? topOffset : '-' + topOffset,
                    marginRight: sideOffset,
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $currentPhotoRight.remove();
                    }
                });
            }
            if ($currentPhotoTextRight.length && !dummy) {
                TweenLite.to($currentPhotoTextRight, 1.0, {
                    top: down ? '+=' + topOffset : '-=' + topOffset,
                    opacity: 0,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $currentPhotoTextRight.remove();
                    }
                });
            }

            if (nextArticleLeft && !dummy) {
                var $nextPhotoLeft = $('<a href="' + "/articles/" + nextArticleLeft.slug + '">')
                        .addClass('js-article-link')
                        .addClass('b-photo-main')
                        .addClass('b-photo-main_next')
                        .addClass('b-photo-main_left')
                        .css({
                            marginTop: down ? topOffset : '-' + topOffset,
                            marginLeft: sideOffset,
                            opacity: 0,
                            backgroundImage: 'url(' + nextArticleLeft.image + ')'
                        })
                    ;
                $('.b-circle_level-2').append($nextPhotoLeft);

                var nextPhotoLeftOffset = $nextPhotoLeft.offset();

                var nextPhotoTextLeft = $(this.mainPhotoTextTemplate(nextArticleLeft))
                        .addClass('b-photo-text_next')
                        .addClass('b-photo-text_left')
                        .css({
                            opacity: 0,
                            top: nextPhotoLeftOffset.top + $nextPhotoLeft.height(),
                            left: $('.b-circle_level-2').offset().left - $('.b-wrapper__inner').offset().left
                        })
                    ;
                $('.b-wrapper__inner').append(nextPhotoTextLeft);

                TweenLite.to($nextPhotoLeft, 1.0, {
                    marginTop: 0,
                    marginLeft: 0,
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $nextPhotoLeft.removeClass('b-photo-main_next').addClass('b-photo-main_current');

                        if (!$currentPhotoLeft.length) {
                            Locker.unlock('movePhotoScene');
                            if (callback) {
                                callback();
                            }
                        }
                    }
                });
                TweenLite.to(nextPhotoTextLeft, 1.0, {
                    top: down ? '-=' + topOffset : '+=' + topOffset,
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        nextPhotoTextLeft.removeClass('b-photo-text_next').addClass('b-photo-text_current');
                    }
                });
            }

            if (nextArticleRight && !dummy) {
                var $nextPhotoRight = $('<a href="' + "/articles/" + nextArticleRight.slug + '">')
                        .addClass('js-article-link')
                        .addClass('b-photo-main')
                        .addClass('b-photo-main_next')
                        .addClass('b-photo-main_right')
                        .css({
                            marginTop: down ? '-' + topOffset : topOffset,
                            marginRight: sideOffset,
                            opacity: 0,
                            backgroundImage: 'url(' + nextArticleRight.image + ')'
                        })
                    ;
                $('.b-circle_level-3').append($nextPhotoRight);

                var nextPhotoRightOffset = $nextPhotoRight.offset();

                var nextPhotoTextRight = $(this.mainPhotoTextTemplate(nextArticleRight))
                        .addClass('b-photo-text_next')
                        .addClass('b-photo-text_right')
                        .css({
                            opacity: 0,
                            top: nextPhotoRightOffset.top + $nextPhotoRight.height(),
                            left: $('.b-circle_level-3').offset().left + $('.b-circle_level-3').width() - $nextPhotoRight.width() - $('.b-wrapper__inner').offset().left
                        })
                    ;
                $('.b-wrapper__inner').append(nextPhotoTextRight);

                TweenLite.to($nextPhotoRight, 1.0, {
                    marginTop: 0,
                    marginRight: 0,
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        $nextPhotoRight.removeClass('b-photo-main_next').addClass('b-photo-main_current');
                    }
                });
                TweenLite.to(nextPhotoTextRight, 1.0, {
                    top: down ? '+=' + topOffset : '-=' + topOffset,
                    opacity: 1,
                    ease: Power2.easeInOut,
                    force3D: false,
                    onComplete: function () {
                        nextPhotoTextRight.removeClass('b-photo-text_next').addClass('b-photo-text_current');
                    }
                });
            }

            if (!dummy) {
                self.moveWatches($('.b-intro__watches'), !!down, cleanUp, false);
            }

            var degreesIntro = {
                3: [190],
                4: [220],
                5: [170],
                6: [180]
            };
            var degreesIntro2 = {
                3: [160, 15, 250],
                4: [163, 250, 280],
                5: [165, 240, 260, 275],
                6: [170, 235, 255, 280]
            };
            var degreesArticle = {
                3: [160, 15, 295, 330],
                4: [165, 20, 290, 320],
                5: [170, 295],
                6: [175, 297]
            };

            var degreesChoices;
            if (this.currentArticleLeft == -1) {
                degreesChoices = degreesIntro;
            } else if (cleanUp) {
                degreesChoices = degreesArticle;
            } else {
                degreesChoices = degreesIntro2;
            }

            TweenLite.to($('.b-circle__planet_3'), 1.3, {
                rotation: _.sample(degreesChoices[3]) + 'deg',
                ease: Power2.easeInOut,
                force3D: false
            });
            TweenLite.to($('.b-circle__planet_4'), 1.7, {
                rotation: _.sample(degreesChoices[4]) + 'deg',
                ease: Power2.easeInOut,
                force3D: false
            });
            TweenLite.to($('.b-circle__planet_5'), 3.3, {
                rotation: _.sample(degreesChoices[5]) + 'deg',
                ease: Power2.easeInOut,
                force3D: false
            });
            TweenLite.to($('.b-circle__planet_6'), 4.3, {
                rotation: _.sample(degreesChoices[6]) + 'deg',
                ease: Power2.easeInOut,
                force3D: false
            });
        },
        render: function () {
            var self = this;

            $('.b-wrapper2').addClass('overflow_hidden');

            $('link[rel=image_src]').attr('href', self.app.config.shareBase + 'static/images/share.png');
            $('title').html('&laquo;Время чемпионов&raquo; на РБК Спорт');
            $('meta[name=description]').attr('content', 'Точность временных замеров на Олимпиаде является одним из ключевых организационных моментов, и поручить это могут только настоящим профессионалам. C 1932 года бренд Omega является официальным хронометристом Олимпийских игр, и летние Олимпийские игры в Рио 2016 года не будут исключением.');

            var articles = [];
            for (var i = 0; i < Math.max(Articles.left.length, Articles.right.length); ++i) {
                if (Articles.left[i]) {
                    articles.push(Articles.left[i]);
                }
                if (Articles.right[i]) {
                    articles.push(Articles.right[i]);
                }
                if (Articles.extra[i] && i == 1) {
                    articles.push({
                        watches: true,
                        html: self.watchesTemplate({index: i+1})
                    });
                }
            }

            this.$el.html(self.app.config.mob ? this.templateMob({
                articles: articles
            }) : this.template());
            TweenLite.to(this.$el, 0.8, {
                opacity: 1,
                ease: Power2.easeInOut,
                force3D: false
            });
            this.$el.addClass('b-container_intro');

            TweenLite.set($('.b-buttons'), {
                bottom: '-7rem',
                opacity: 0
            });

            $('.b-wrapper2').animate({scrollTop: 0}, '200', 'swing');
            if (self.app.config.mob) {
                self.mouseWheelOn($('.b-intro__watches'), false, function (dir, e) {
                    var $currentTarget = $(e.currentTarget);
                    self.moveWatches.call(self, $currentTarget, dir, false, true);
                    return false;
                }, true);
            }


            this.app.counters.hit('intro');

            return this;
        },

        setTimeout: function () {
            this.timeout = setTimeout(function () {
                ga("send", "event", "page", "spent_on_page_15_sec");
            }, 15000);
        },

        clearTimeout: function () {
            clearTimeout(this.timeout);
        },

        init: function () {
            var self = this;
            this.currentArticleLeft = -1;
            this.currentArticleRight = -1;
            if (!this.app.config.mob) {
                this.mouseWheelOn($(document), true, function (dir) {
                    self.movePhotoScene(dir)
                });
                this.movePhotoScene(-2, true);
            }
            $('body').on('keydown', self.onKeyDown.bind(self));
        },

        start: function () {
            this.app.counters.hit('start-button');
            return false;
        }
    });
});

