/**
 * Created by penyaev on 26.11.15.
 */

define('api',[
  'underscore',
  'jquery'
], function (_, $) {
  var _private = {
    apiURL: false,
    apiPersistentData: {
      'v': '1' // api version
    },
    apiMethod: 'get',

    getMethodUrl: function (methodName, args) {
      var url = _private.apiURL + methodName;
      var urlArgs = [];
      if (typeof args === 'object') {
        for (var i in args) {
          if (args[i] !== undefined) {
            urlArgs.push(i + '=' + encodeURIComponent(args[i]));
          }
        }
      }
      if (urlArgs.length) {
        url += '?' + urlArgs.join('&');
      }
      return url;
    },
    apiCall: function (method, data, options) {
      options = options || {};
      data = data || {};
      var requestData = _.extend({}, _private.apiPersistentData, data);
      $.ajax({
        method: options.method || _private.apiMethod,
        url: _private.getMethodUrl(method),
        data: requestData,
        beforeSend: options.onProgressStart || function () {
        },
        complete: options.onProgressEnd || function () {
        },
        success: options.onSuccess || function () {
        },
        error: options.onError || function () {
        }
      });
    },
    init: function (apiURL) {
      _private.apiURL = apiURL;
    },
    setApiPersistentData: function (key, value) {
      _private.apiPersistentData[key] = value;
    }
  };

  var _public = {
    apiCall: _private.apiCall,
    init: _private.init,
    setApiPersistentData: _private.setApiPersistentData
  };

  return _public;
});

