/**
 * Created by penyaev on 23.03.16.
 */


define('data/counters',[],function () {
    return {
        /*"index": {
            "web": "//rs.mail.ru/d19382833.gif"
        }*/
    };
});

