define('templates/articles/articles', [
    'text!templates/articles/1.html',
    'text!templates/articles/2.html',
    'text!templates/articles/3.html',
    'text!templates/articles/4.html',
    'text!templates/articles/5.html',
    'text!templates/articles/6.html',
    'text!templates/articles/7.html',
    'text!templates/articles/8.html',
    'text!templates/articles/9.html',
    'text!templates/articles/10.html'
], function () {
    var result = {};
    for (var i in arguments) {
        result[parseInt(i) + 1 + '.html'] = arguments[i]
    }
    return result;
});

