/**
 * Created by penyaev on 04.08.16.
 */


define('data/articles',[],function () {
    return {
        "left": [
            {
                "slug": "a1",
                "image": "static/images/articles/001.jpg",
                "mobimage": "static/images/articles/mob/001@2x.jpg",
                "title": "ОСНОВНЫЕ МОМЕНТЫ<br>ИСТОРИИ ХРОНОМЕТРАЖА<br>OMEGA",
                "mobtitle": "ОСНОВНЫЕ МОМЕНТЫ ИСТОРИИ ХРОНОМЕТРАЖА OMEGA",
                "category": "ретроспектива",
                "description": "Победу определяют миллисекунды, поэтому<br>мы с увлечением и сознанием своего долга<br>принимаем участие в этом выдающемся<br>событии",
                "description_footer": "Победу определяют миллисекунды, поэтому мы с увлечением и сознанием своего долга принимаем участие в этом выдающемся событии",
                "html": "1.html",
                "footers": ["a2", "a3", "a8"]
            }, {
                "slug": "a3",
                "image": "static/images/articles/002.jpg",
                "mobimage": "static/images/articles/mob/002@2x.jpg",
                "title": "ЭВОЛЮЦИЯ ХРОНОМЕТРАЖА OMEGA",
                "mobtitle": "ЭВОЛЮЦИЯ ХРОНОМЕТРАЖА OMEGA",
                "category": "история",
                "description": "Спортсмены становятся быстрее и сильнее, и OMEGA<br>также отточила свое мастерство в сфере<br>хронометража Олимпийских игр. В частности,<br>огромную эволюцию претерпели три технологии, что<br>демонстрирует достигнутый нами прогресс.",
                "description_footer": "Спортсмены становятся быстрее и сильнее, и OMEGA также отточила свое мастерство в сфере хронометража Олимпийских игр. В частности, огромную эволюцию претерпели три технологии, что демонстрирует достигнутый нами прогресс.",
                "html": "3.html",
                "footers": ["a4", "a5", "a10"]
            }, {
                "slug": "a5",
                "image": "static/images/articles/003.jpg",
                "mobimage": "static/images/articles/mob/003@2x.jpg",
                "title": "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ ЗАПЛЫВА<br>НА 100 МЕТРОВ ВОЛЬНЫМ СТИЛЕМ",
                "mobtitle": "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ ЗАПЛЫВА НА 100 МЕТРОВ ВОЛЬНЫМ СТИЛЕМ",
                "main_title": "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ<br>ЗАПЛЫВА НА 100 МЕТРОВ ВОЛЬНЫМ<br>СТИЛЕМ",
                "category": "технологии",
                "description": "С самого первого момента заплыва на 100 метров<br>вольным стилем OMEGA обеспечивает абсолютную<br>точность хронометража.",
                "description_footer": "С самого первого момента заплыва на 100 метров вольным стилем OMEGA обеспечивает абсолютную точность хронометража.",
                "footers": ["a6", "a7", "a9"],
                "html": "5.html"
            }, {
                "slug": "a7",
                "image": "static/images/articles/004.jpg",
                "mobimage": "static/images/articles/mob/004@2x.jpg",
                "title": "РОЛЬ СУДЕЙСКОГО КОЛОКОЛА<br>В ВЫСОКОТЕХНОЛОГИЧНОМ ХРОНОМЕТРАЖЕ OMEGA",
                "mobtitle": "РОЛЬ СУДЕЙСКОГО КОЛОКОЛА В ВЫСОКОТЕХНОЛОГИЧНОМ ХРОНОМЕТРАЖЕ OMEGA",
                "category": "детали",
                "description": "Миллионы людей во всем мире увидят и услышат<br>судейские колокола OMEGA, отлитые почти<br>полностью вручную в мастерской Блондо вблизи<br>города Ла-Шо-де-Фон в швейцарских Альпах.",
                "description_footer": "Миллионы людей во всем мире увидят и услышат судейские колокола OMEGA, отлитые почти полностью вручную в мастерской Блондо вблизи города Ла-Шо-де-Фон в швейцарских Альпах.",
                "html": "7.html",
                "footers": ["a1", "a2", "a8"]
            }
        ],
        "right": [
            {
                "slug": "a2",
                "image": "static/images/articles/005.jpg",
                "mobimage": "static/images/articles/mob/005@2x.jpg",
                "title": "OMEGA НА ОЛИМПИАДЕ–2016<br> В РИО-ДЕ-ЖАНЕЙРО",
                "mobtitle": "OMEGA НА ОЛИМПИАДЕ–2016 В РИО-ДЕ-ЖАНЕЙРО",
                "category": "оборудование",
                "description": "Сейчас внимание всего мира направлено на Рио-де-<br>Жанейро, и бренд OMEGA готовится к выполнению своих<br>обязанностей хронометриста в 27-й раз.",
                "description_footer": "Сейчас внимание всего мира направлено на Рио-де-Жанейро, и бренд OMEGA готовится к выполнению своих обязанностей хронометриста в 27-й раз.",
                "html": "2.html",
                "footers": ["a3", "a4", "a9"]
            }, {
                "slug": "a4",
                "image": "static/images/articles/006.jpg",
                "mobimage": "static/images/articles/mob/006@2x.jpg",
                "title": "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ <br> ЗАБЕГА НА 100 МЕТРОВ",
                "mobtitle": "КАК OMEGA ИЗМЕРЯЕТ РЕЗУЛЬТАТЫ ЗАБЕГА НА 100 МЕТРОВ",
                "category": "технологии",
                "description": "Пожалуй, самый знаменитый и культовый момент<br>Олимпийских игр, забег на 100 метров, начинается<br>с выстрела стартового пистолета.",
                "description_footer": "Пожалуй, самый знаменитый и культовый момент Олимпийских игр, забег на 100 метров, начинается с выстрела стартового пистолета.",
                "html": "4.html",
                "footers": ["a5", "a6", "a8"]
            }, {
                "slug": "a6",
                "image": "static/images/articles/007.jpg",
                "mobimage": "static/images/articles/mob/007@2x.jpg",
                "title": "НОВЫЕ ХРОНОМЕТРАЖНЫЕ ТЕХНОЛОГИИ<br>НА ОЛИМПИАДЕ–2016 В РИО-ДЕ-ЖАНЕЙРО",
                "mobtitle": "НОВЫЕ ХРОНОМЕТРАЖНЫЕ ТЕХНОЛОГИИ НА ОЛИМПИАДЕ–2016 В РИО-ДЕ-ЖАНЕЙРО",
                "category": "технологии",
                "description": "Всё о технических новинках OMEGA на Олимпиаде–2016",
                "description_footer": "Всё о технических новинках OMEGA на Олимпиаде–2016",
                "html": "6.html",
                "footers": ["a7", "a1", "a10"]
            }
        ],
        "extra": [
            {
                "url": "http://www.omegawatches.com/ru/watches/specialities/olympic-collection/52230412001001/",
                "slug": "a8",
                "title": "ОГРАНИЧЕННАЯ СЕРИЯ<br>SEAMASTER DIVER 300 M &laquo;RIO 2016&raquo;",
                "mobtitle": "ОГРАНИЧЕННАЯ СЕРИЯ SEAMASTER DIVER 300 M &laquo;RIO 2016&raquo;",
                "image": "static/images/articles/008.jpg",
                "mobimage": "static/images/watches/watch_001@2x.jpg",
                "category": "новая коллекция",
                "description": "Мы выпустили специальную ограниченную коллекцию<br> к Летней Олимпиаде в Рио-де-Жанейро",
                "description_footer": "Мы выпустили специальную ограниченную коллекцию к Летней Олимпиаде в Рио-де-Жанейро",
                "html": "8.html",
                "footers": ["a2", "a3"]
            }, {
                "url": "http://www.omegawatches.com/ru/watches/specialities/olympic-collection/52212435004001/",
                "slug": "a9",
                "title": "ОГРАНИЧЕННАЯ СЕРИЯ <br> SEAMASTER BULLHEAD &laquo;RIO 2016&raquo;",
                "mobtitle": "ОГРАНИЧЕННАЯ СЕРИЯ SEAMASTER BULLHEAD &laquo;RIO 2016&raquo;",
                "image": "static/images/articles/009.jpg",
                "mobimage": "static/images/watches/watch_002@2x.jpg",
                "category": "новая коллекция",
                "description": "Мы выпустили специальную ограниченную коллекцию<br> к Летней Олимпиаде в Рио-де-Жанейро",
                "description_footer": "Мы выпустили специальную ограниченную коллекцию к Летней Олимпиаде в Рио-де-Жанейро",
                "html": "9.html",
                "footers": ["a3", "a4"]
            }, {
                "url": "http://www.omegawatches.com/ru/watches/specialities/olympic-collection/52210435001001/",
                "slug": "a10",
                "title": "SPEEDMASTER MARK II &laquo;RIO 2016&raquo;",
                "mobtitle": "SPEEDMASTER MARK II &laquo;RIO 2016&raquo;",
                "image": "static/images/articles/010.jpg",
                "mobimage": "static/images/watches/watch_003@2x.jpg",
                "category": "новая коллекция",
                "description": "Мы выпустили специальную ограниченную коллекцию <br> к Летней Олимпиаде в Рио-де-Жанейро",
                "description_footer": "Мы выпустили специальную ограниченную коллекцию  к Летней Олимпиаде в Рио-де-Жанейро",
                "html": "10.html",
                "footers": ["a4", "a5"]
            }
        ]
    };
});

